.NavigationItems {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-flow:column;
    align-items:center;
    height: 100%;
    
}

@media (min-width:500px) {
    .NavigationItems {
        flex-flow: row;
    }
}